import { render, staticRenderFns } from "./IssueResolve.vue?vue&type=template&id=0ba3e9e4&scoped=true"
import script from "./IssueResolve.vue?vue&type=script&lang=js"
export * from "./IssueResolve.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ba3e9e4",
  null
  
)

export default component.exports